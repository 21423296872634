.box_shadow_full {
  padding: 2rem 1.25rem;
  position: relative;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.box_shadow_about {
  text-decoration: none;
  box-shadow: 0 0 0 4px #cde1f8;
}

.about_me {
  padding-top: 2rem;
  margin-bottom: 1rem;
}

.about_section .about_img {
  margin-bottom: 2rem;
  margin-left: 10px;
}

.about_info .box_shadow_full {
  padding-bottom: 4rem;
}

.about_info .about_img {
  margin-bottom: 2rem;
}

.about_info .about_img img {
  margin-left: 10px;
}

.skills {
  font-size: 1.3rem;
}

.skill_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

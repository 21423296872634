.header {
  /* I learned to do the backgrand gradient over the image from 
      Advanced CSS and Sass: Flexbox, Grid, Annimation and More! - by Jonas Schmedtmann at https://www.udemy.com/ */
  background-image: linear-gradient(
      to right bottom,
      rgba(123, 159, 172, 0.5),
      /* rgba(12, 186, 186, 0.5),  */ rgba(123, 109, 141, 0.5)
    ),
    /* rgba(56, 0, 54, 0.5)), */ url(/public/img/code.jpg);
  width: 100%;
  /* height: 70vh; */
  background-size: cover;
  background-position: top;
  padding: 60px;
}

.header_img img {
  width: 40%;
  border-radius: 100%;
}

.centered {
  text-align: center;
}

.header_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.display_1 {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.1;
  color: #fff;
}

.display_2 {
  font-size: 2.1rem;
  font-weight: 300;
  line-height: 1.1;
  color: #fff;
}

@media screen and (max-width: 991px) {
  .display_1 {
    font-size: 1.8rem;
  }

  .display_2 {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1;
    color: #fff;
  }
  title {
    font-size: 12rem;
  }
}

footer {
  background-color: #3a606e;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
}

footer,
a {
  color: #e0e0e0;
}

a:visited {
  color: #e0e0e0;
}

a:hover {
  color: gray;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.7;
  color: #777;
  background-color: #e0e0e0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e1e;
}
hr {
  width: 100%;
  border: 2px solid #fff;
  border-radius: 5px;
}

p a:link {
  color: #000000;
}

p a:visited {
  color: gray;
}
p a:hover {
  color: #3a606e;
}
